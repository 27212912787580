import React from 'react'
import styled from 'styled-components'

const icon = require('../resources/img/vp_logo_oval_trans.png')

export default ({ image, imageSpan, light, title, content, flip, paddingTop, showIcon }) => {
  const leftContent = (
    <div className="picture">
      <img alt="" src={image}/>
    </div>
  )
  const rightContent = (
    <div className="content-wrapper" style={{paddingTop}}>
      {showIcon!==false && <img src={icon} alt="" width={64} className="mb-5" />}
      <h2>{title}</h2>
      <div className="content">
        {content}
      </div>
    </div>
  )

  const leftSize = imageSpan || 5
  const rightSize = 12 - 1 - leftSize

  if (flip) {
    return (
      <Root className="row">
        <div className={`col-${rightSize}`}>{rightContent}</div>
        <div className={`col-${leftSize} offset-1`}>{leftContent}</div>
      </Root>
    )
  }

  return (
    <Root className={"row " + (light ? 'light' : '')}>
      <div className={`col-${leftSize}`}>{leftContent}</div>
      <div className={`col-${rightSize} offset-1`}>{rightContent}</div>
    </Root>
  )
}

const Root = styled.div`

  padding: 80px 0;

  .picture {
    display: block;
    
    img {
      border-radius: 4px !important;
    }
  }
  
  .content-wrapper {
    padding-top: 35px;
  }

  h2 {
    font-size: 2.5em;
    font-weight: 800;
    margin: 0 0 30px;
    letter-spacing: 0;
  }
  
  .content * {
    text-align: justify;
    font-size: 1.1em;
    line-height: 1.5;
  }
  
  &.light h2 {
    color: #FFF;
  }
  &.light .content * {
    color: #AFAFAF;
  }
`
