import React, { Fragment } from 'react'
import PageHero from '../components/PageHero'
import Layout from '../components/Layout'
import Content from '../components/Content'

const assets = {
  product_checkout: require('../resources/img/product_checkout.png'),
  product_paypal: require('../resources/img/product_vitepay.png'),
  product_business: require('../resources/img/product_business.jpeg'),
  product_delivery: require('../resources/img/product_delivery.jpg'),
  product_website: 'https://cdn.shopify.com/s/files/1/1363/7947/t/27/assets/image_grid3.jpg?15579909466577615793',
}

export default () => (
  <Layout page="products" className="hero-distort-1">
    <PageHero title="Produits Vitepay"
              content={
                <Fragment>
                  Tout au long de l'expérience e-commerce,<br/>
                  Vitepay compte proposer des services à forte valeur ajoutée
                </Fragment>
              }/>

    <div className="clearfix container mt-5">
      <Content
        image={assets.product_checkout}
        flip showIcon={false} paddingTop={150}
        title={'Vitepay Checkout'}
        content={
          <div>
            <p>
              Les sites e-commerce intégrant Vitepay peuvent en <strong>quelques minutes</strong>&nbsp;
              rajouter un formulaire de paiement intuitif et rapide. Sans quitter le site, les client.e.s
              sont invité.e.s à entrer leur numéro de téléphone pour effectuer de façon sécurisée le paiement
              mobile.
            </p>
          </div>
        }
      />
    </div>

    <div className="clearfix distort-green">
      <div className="container">
        <Content
          image={assets.product_website}
          showIcon={false} paddingTop={100}
          title={'Vitepay Boutik\''}
          content={
            <div>
              <h3>Vous avez des produits magnifigues, tout ce qui vous manque c'est une boutique en ligne !</h3>
              <p className="mt-5">
                Votre créativité ne devrait pas être freinée par des contraintes techniques
                de création de boutique en ligne. Vitepay veut démocratiser l'e-commerce au Mali et
                cette ambition passe par les boutiques en web et mobile pour tous les marchands.
              </p>
              <p>
                Si vous savez utiliser Facebook, vous saurez gérer votre boutique.
              </p>
            </div>
          }
        />
      </div>
    </div>

    <div className="clearfix ">
      <div className="container">
        <Content
          image={assets.product_business} imageSpan={6} flip
          showIcon={false} paddingTop={50}
          title={'Vitepay Centrale'}
          content={
            <div>
              <p>
                La Centrale de paiement est une collaboration avec les entreprises
                de moyenne et grande taille pour les aider à intégrer Vitepay dans
                leur stratégie de développement dans le dommaine du commerce électronique.
              </p>
            </div>
          }
        />
      </div>
    </div>

    <div className="clearfix distort-blue">
      <div className="container">
        <Content
          image={assets.product_delivery}
          showIcon={false} paddingTop={60}
          title={'Vitepay Livraison'}
          content={
            <div>
              <p>
                Un achat en ligne est très souvent suivi d'une livraison. Fournir un tel service n'est pas
                toujours une démarche simple pour les marchands. Une mauvaise qualité dans cette démarche peut impacter
                négativement un marchand voire l'indice de confiance local de l'e-commerce des consommateurs.
              </p>
              <p>
                Vitepay teste actuellement avec les marchands sélectionnés un service de livraison simple à mettre en
                place et garantissant aux consommateurs un haut indice de satisfaction.
              </p>
            </div>
          }
        />
      </div>
    </div>

    <div className="clearfix">
      <div className="container">
        <Content
          image={assets.product_paypal} flip
          showIcon={false} paddingTop={120}
          title={'Vitepay Paypal !!'}
          content={
            <div>
              <p>
                Besoin d'effectuer un transfert depuis votre compte mobile money vers Paypal pour
                effectuer de paiements étrangers ? Vitepay peut vous aider ;-)
              </p>
            </div>
          }
        />
      </div>
    </div>
    <hr style={{borderColor: '#AED5EE'}}/>
  </Layout>
)
